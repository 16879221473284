import React from 'react'
import { Typography, Stack, Button } from '@mui/material'
import { navigate } from 'gatsby'
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'

const goHome = () => navigate('/')

const EmptyFavorites = () => (
  <Stack justifyContent="center" m="2rem auto" sx={{ maxWidth: '350px' }}>
    <Typography variant="subtitle1" sx={{ my: 2, textAlign: 'center' }}>
      No Favorites... yet!
    </Typography>
    <Typography sx={{ textAlign: 'center', marginBottom: '2rem' }}>
      To add an item you love to your Favorites simply click on the (
      <FavoriteBorderOutlinedIcon color="error" sx={{ fontSize: '1.2rem', marginBottom: '-5px' }} />
      ️) icons located anywhere on this website.
    </Typography>
    <Button variant="outlined" color="primary" size="large" fullWidth onClick={goHome}>
      Continue Shopping
    </Button>
  </Stack>
)

export default EmptyFavorites
