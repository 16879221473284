import React from 'react'

import { Stack, Skeleton } from '@mui/material'

const ProductLoader = () => (
  <Stack spacing={1}>
    <Skeleton variant="rectangular" sx={{ width: '100%', paddingBottom: '80%' }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="80%" />
  </Stack>
)

export default ProductLoader
